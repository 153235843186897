.squareContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 4 ячейки в ряд */
    grid-gap: 10px; /* Расстояние между ячейками */
    width: 400px; /* Ширина квадрата */
    height: 400px; /* Высота квадрата */
}

.squareCell {
    position: relative;
    background-color: #eee; /* Цвет фона ячеек */
    border: 1px solid #ddd; /* Граница ячеек */
    aspect-ratio: 1 / 1; /* Соотношение сторон для квадратных ячеек */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.deleteIcon {
    position: absolute;
    right: 0;
    top: 0;
}

.item {
    position: absolute;
    left: 0;
    top: 0;
}

.deleteIcon:hover {
  opacity: 0.6;
}

.probability {
    font-size: 12px;
}
