.Items {
    margin-bottom: 100px;
}


.AddItem {
    /*padding: 20px;*/
}

.contentWrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
}

.formItem {
    margin: 5px !important;
}

.pagination {
    display: flex;
    margin-top: 15px;
    justify-content: center;
}

.addItemButton {
    margin-bottom: 20px;
}
.addItemButton button {
    margin-right: 20px;
}
.tableRow {
    cursor: pointer;
}
.listWrapper {
    margin: 15px
}

.checkbox {
    display: flex;
    align-items: center;
}

.find {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
}

.modelId {
    width: 300px;
}

.Image {
    width: 40px;
}
