.textarea {
    margin-top: 10px;
    margin-right: 10px;
}

.buttons {
    margin: 5px;
}

.title {
    margin: 5px;
}

.buttonWrapper {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
}
