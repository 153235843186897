.Header {
}

.mainContent {
    padding-top: 100px; /* Примерная высота AppBar. Может потребоваться корректировка */
}

.menu {
    cursor: pointer;
}

.menu:hover {
    opacity: 0.8;
}
