/* references.module.css */
.container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    padding: 10px;
}

.column {
    border: 1px solid #ccc;
    padding: 10px;
    height: 600px; /* Высота по вашему усмотрению */
    overflow-y: auto; /* Для прокрутки если элементов много */
}

.item {
    padding: 5px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.item:hover {
    background-color: #f0f0f0;
}

.selectedItem {
    background-color: #e0e0e0;
}

.header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

.addButton {
    margin-bottom: 10px!important;
}

.addButton:hover {
    background-color: #45a049;
}

.rarities {
    margin-left: 50px;
}



