.Items {
    margin-bottom: 100px;
}


.AddItem {
    padding: 20px;
}

.formItem {
    margin: 10px;
}

.addItemButton {
    margin-bottom: 20px;
}

.find {
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
}

.pagination {
    display: flex;
    margin-top: 15px;
    justify-content: center;
}

.modelId {
    width: 300px;
}

.recipesId {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
}


.wrapper {
    display: flex;
    justify-content: center;
}

.cardSpace {
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardWrapper {
  margin: 10px
}

.cardContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperButton {
    display: flex;
    margin-top: 10px;
    justify-content: center;
}

.wrapperButton>button {
    margin: 10px;
}

.wrapperButton>a {
    margin: 10px;
}

.items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.randomItem {
    position: relative;
    border: 1px solid;
    padding: 5px;
    margin: 5px
}

.checkbox {
    display: flex;
    align-items: center;
}

.deleteIcon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
}

.deleteIcon:hover {
    opacity: 0.6;
}
